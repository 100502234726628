// Fill ajax response values
window.fillFormAjax = function(form, data, keyUpper = false, keyPrefix = '') {
    for (const [key, val] of Object.entries(data)) {
        let inputKey = key;
        if (keyUpper) {
            inputKey = key.charAt(0).toUpperCase() + key.slice(1);
        }
        const elem = form.querySelector('#' + keyPrefix + inputKey);
        if (!elem)
            continue;
        switch (elem.tagName.toLowerCase()) {
            case "textarea":
                elem.innerHTML = val;
                break;
            case "input":
                const type = elem.getAttribute('type').toLowerCase();
                const inputTypes = ['text', 'password', 'number', 'email', 'tel', 'url', 'search', 'date', 'datetime', 'datetime-local', 'time', 'month', 'week'];
                if (inputTypes.indexOf(type) >= 0) {
                    elem.value = val;
                }
                break;
        }
    }
}
