$(document).ready(function() {
    // Add event listener for table row click
    $('.table-hover tr[data-href] td:not([data-href])').on('click', function() {
        if ($(this).hasClass('td-checkbox') || $(this).hasClass('td-dropdown'))
            return true;
        if ($(this).parents('tr').attr('data-target') === '_blank')
            window.open($(this).parents('tr').attr('data-href'));
        else
            window.location.href = $(this).parents('tr').attr('data-href');
    });

    // Firefox bugfix
    $(document).click(function(e) {
        if (e.button === 0) {
            $('.table-context-menu .dropdown .dropdown-menu.show').removeClass('show');
            $('.table-context-menu .dropdown .dropdown-menu').removeAttr('style');
        }
    });

    // tr right click
    $('body').on('contextmenu', '.table-context-menu tbody tr', function(e) {
        $('.table-context-menu .dropdown .dropdown-menu.show').removeClass('show');
        $('.table-context-menu .dropdown .dropdown-menu').removeAttr('style');

        let left = e.pageX;
        let top = e.pageY;

        if (e.pageY + $(this).find('.dropdown-menu').outerHeight() > $(window).height() + window.pageYOffset ||
            ($(this).closest('table').parent().hasClass('table-responsive') && $(this).find('.dropdown-menu').outerHeight() + e.pageX > $(this).closest('table').outerHeight() + $(this).closest('table').offset().top)) {
            top = (e.pageY - $(this).find('.dropdown-menu').outerHeight());
        }
        if (e.pageX + $(this).find('.dropdown-menu').outerWidth() > $(window).width() + window.pageXOffset ||
            ($(this).closest('table').parent().hasClass('table-responsive') && $(this).find('.dropdown-menu').outerWidth() + e.pageX > $(this).closest('table').outerWidth() + $(this).closest('table').offset().left)) {
            left = (e.pageX - $(this).find('.dropdown-menu').outerWidth());
        }

        $(this).find('.dropdown-menu').css({
            position: 'absolute',
            left: left,
            top: top,
            bottom: 'auto',
            right: 'auto',
            transform: 'translateX(-' + $(this).find('.dropdown').offset().left + 'px) translateY(-' + $(this).find('.dropdown').offset().top + 'px)'
        });

        $(this).find('.dropdown-menu').addClass('show');
        return false;
    });
});
