$(document).ready(function() {
    // Fill modal dataset values
    $('.modal[data-type="href"]').on('show.bs.modal', function(e) {
        if (!e.relatedTarget)
            return;
        const data = $(e.relatedTarget).data();
        for (const k in data)
            $(this).find('.data-'+k).text(data[k]);
    });
});
